import LandingPageSection from './LandingPageSection';
import PropTypes from 'prop-types';

const LandingPage = ({ landingPage }) => {
	if (!landingPage || !landingPage.sections || landingPage.sections.length === 0) {
		return null;
	}

	return (
		<div className="mb-4 bg-v2-off-white">
			{landingPage.sections.map((section, sectionIndex) => {
				return (
					<LandingPageSection landingPage={landingPage} key={sectionIndex} section={section} />
				);
			})}
		</div>
	);
};

LandingPage.propTypes = {
	landingPage: PropTypes.object,
};

export default LandingPage;
