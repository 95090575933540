import HeroText from '/components/lp/sections/hero/HeroText';
import Image from '/components/Image';
import PropTypes from 'prop-types';
import classNames from '/utils/class-names';
import { getTestVariant } from '/services/ab-test';
import { useEffect, useState } from 'react';

const HeroV2 = ({ config }) => {
	const alignments = {
		center: 'lg:mx-auto',
		left: 'xl:ml-[4vw]',
		right: 'xl:mr-[4vw]',
	};

	const [variantKey, setVariantKey] = useState();
	const style = {
		height: '100%',
	};

	useEffect(() => {
		if (config.videoSrc && config.videoPosterSrc) {
			const fetchVariant = async () => {
				const variation = await getTestVariant('homepage-video-2024');
				setVariantKey(variation.key);
			};
			fetchVariant();
		}
	}, [config.videoSrc, config.videoPosterSrc]);
	return (
		<div className="relative bg-gray-900 mb-4 md:mb-8 overflow-hidden">
			{!config.videoSrc || !config.videoPosterSrc ? (
				<div className="absolute inset-0 overflow-hidden">
					<div className="block md:hidden h-full w-full">
						<Image
							alt="Shinesty homepage image mobile"
							className="object-cover object-center"
							layout="fill"
							src={config.imageSrcMobile}
							priority
						/>
					</div>
					<div className="hidden md:block h-full w-full">
						<Image
							alt="Shinesty homepage image desktop"
							className="object-cover object-left"
							layout="fill"
							src={config.imageSrcDesktop}
							priority
						/>
					</div>
				</div>
			) : !variantKey ? (
				<div style={style}>
					<Image
						alt="Hero Image"
						className="object-cover object-center"
						layout="fill"
						src={config.videoPosterSrc}
						priority
					/>
					{config.showOverlay && <div className="absolute inset-0 bg-gray-900 bg-opacity-60"></div>}
				</div>
			) : variantKey === 'show-video' ? (
				<div aria-hidden="true" className="absolute inset-0 overflow-hidden">
					<Image
						alt="Video poster"
						className="object-cover object-center"
						layout="fill"
						src={config.videoPosterSrc}
						priority
					/>
					{config.showOverlay && <div className="absolute inset-0 bg-gray-900 bg-opacity-60"></div>}
				</div>
			) : (
				<div aria-hidden="true" className="absolute inset-0 overflow-hidden">
					<video
						aria-label=""
						autoPlay={'autoplay'}
						className="h-full w-full object-cover object-center"
						controlsList="nodownload"
						id="indexVideo"
						loop
						muted
						playsInline
						poster={config.videoPosterSrc}
						preload="metadata">
						<source src={config.videoSrc} type="video/mp4" />
					</video>
				</div>
			)}
			{config.showOverlay && <div className="absolute inset-0 bg-gray-900 bg-opacity-60"></div>}

			<div
				className={classNames(
					'relative mx-auto flex max-w-3xl flex-col items-center px-6 py-32 text-center sm:py-64 lg:px-0 text-v2-off-white',
					alignments[config.alignment] ? alignments[config.alignment] : alignments.left,
				)}>
				<HeroText config={config} />
			</div>
		</div>
	);
};

HeroV2.propTypes = {
	config: PropTypes.object,
};

export default HeroV2;
