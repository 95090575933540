import Clickable from '/components/Clickable';
import Image from '/components/Image';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';

const LinkList = ({ config }) => {
	if (!config || !config.links) {
		return null;
	}

	return (
		<div className="container max-w-7xl mb-4">
			{config.heading && (
				<Typography className="mb-4 text-center" component="h3" variant="subhead-lg">
					{config.heading}
				</Typography>
			)}
			<div className="flex flex-wrap items-stretch justify-center">
				{config.links.map((link, i) => {
					return (
						<Clickable
							className="border-gray-300 border-[1px] duration-300 flex flex-col hover:bg-gray-100 items-center justify-center m-2 p-4 rounded-[4px] transition-colors"
							linkToInternal={link.anchorHref}
							heapEventName="carousel-links-with-image-click"
							heapEventData={{ 'click-anchor-href': link.anchorHref, 'click-title': link.title }}
							prefetch={false}
							key={i}>
							{link.imageSrc && (
								<div className="mb-6 h-[42px] w-[42px] relative">
									<Image
										alt={'Image of' + link.altText}
										src={link.imageSrc}
										objectFit="cover"
										layout="fill"
									/>
								</div>
							)}
							<Typography component="h3" variant="heading-xs">
								{link.title}
							</Typography>
						</Clickable>
					);
				})}
			</div>
		</div>
	);
};

LinkList.propTypes = {
	config: PropTypes.object,
};

export default LinkList;
