import Clickable from '../../Clickable';
import InputGroup from '../../InputGroup';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';

import classNames from '../../../utils/class-names';

import { subscribeEmail } from '../../../services/email-list';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useRef, useState } from 'react';

const Klaviyo = ({ config }) => {
	const [email, setEmail] = useState('');
	const [formState, setFormState] = useState({});
	const inputRef = useRef(null);
	const { executeRecaptcha } = useGoogleReCaptcha();

	const submitForm = async (event) => {
		event.preventDefault();

		if (!config.listKey || config.listKey === '') {
			setFormState({
				message: 'no list key provided',
				status: 'error',
			});
			return;
		}

		const response = await subscribeEmail({
			email,
			listKeys: [config.listKey],
			executeRecaptcha,
			recaptchaKey: 'footer',
			successMessage: 'Horray you are subscribed!',
		});

		setFormState(response);
	};

	return (
		<div className="container max-w-md mb-3 md:mb-4">
			{config.heading && (
				<Typography className="mb-2 text-center md:mb-4" component="h2" variant="subhead-lg">
					{config.heading}
				</Typography>
			)}
			<form action="#" onSubmit={submitForm}>
				<div className="flex justify-center">
					<InputGroup
						ref={inputRef}
						type="text"
						className="text-gray-600 border rounded-l-full border-gray-500 mb-4 placeholder-gray-600 px-2"
						adaTitle="Email"
						placeholder="email@example.com"
						value={email}
						onChange={(val) => {
							setEmail(val);
						}}
					/>
					<Clickable
						buttonType="submit"
						className="rounded-r-full mb-4 bg-primary !px-3 whitespace-nowrap"
						variant="filled">
						{config.cta || 'Notify Me'}
					</Clickable>
				</div>
				{formState && formState.message && (
					<div className="flex justify-center mb-4">
						<Typography
							className={classNames(
								'bg-v2-off-white italic px-2 py-1 rounded-full',
								formState.status === 'success' ? 'text-green-700' : 'text-primary',
							)}>
							{formState.message}
						</Typography>
					</div>
				)}
			</form>
		</div>
	);
};

Klaviyo.propTypes = {
	config: PropTypes.object,
};

export default Klaviyo;
